<template>
<v-container fluid>
  <v-row>
      <action-bar entity="bug-report"></action-bar>
      <v-col cols=11>
        <v-card>
          <v-card-title>
            <h2 class="title">Bugs</h2>
          </v-card-title>
        </v-card>

      <div v-if="items">
            <default-table
              v-if="!$route.params.action"
              :items="items"
              :headers="headers"
              endpoint="bugReport"
              entity="bugReport"
              :actions="['edit', 'delete']"
              :statusKeys="[]"
              name="Bugs"
            >
            </default-table>

            <bugReport-form :item="item" v-if="($route.params.action === 'edit' && $route.params.id) || ($route.params.action === 'create' && !$route.params.id)"></bugReport-form>
      </div>
      
      <div v-if="$store.state.loading">  
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="'/static/illustrations/' + image + '.svg'"
            width="100%"
            icon
          />
      </div>

    </v-col>
  </v-row>
</v-container>
</template>
<script>

import ActionBar from '../../components/ActionBar'
import BugReportForm from '../components/BugReportForm'
import DefaultTable from '../../components/DefaultTable'
const crudMixin = require('../../addons/mixins.js')
const {displayBugReport, processBugReport, bugReportModel, bugReportSchema} = require('../descriptors.js')

export default {
    name: 'ListBugReport',
    components: {
        ActionBar,  
        DefaultTable,
        BugReportForm,
    },
    mixins: [crudMixin.crudMixin],
    data() {
        return {
            'image': '',
            'entity': 'bug-report',
            'addAction': 'addBugReport',
            'updateAction': 'updateBugReport',
            'removeAction': 'removeBugReport',
            'loadAction': 'getBugReport',
            'loadAllAction': 'getTypes',
            'redir': '/bug-report'
        }
    },
    computed: {
      view: function() {
        return this.$route.params.action
      },
      processed: function() {
        return processBugReport(this.item)
      },
      schema: function() {
        return bugReportSchema
      },
      raw_items: function() {
        return this.$store.getters.bugList
      },
      items: function() {
        return this.$store.getters.bugList.map(i => {return displayBugReport(i, this.$store.getters.dictionary)})
      },
      raw_item: function() {
        if (this.$route.params.action === 'create') {
          return bugReportModel()
        }
        return this.$store.getters.currentBug
      },
      item: function() {
        if (this.$route.params.action === 'create') {
          return bugReportModel()
        }
        return displayBugReport(this.$store.getters.currentBugReport, this.$store.getters.dictionary)
      },
      headers: function() {
        return [
          { text: 'Link', value: 'link'},
          { text: 'Descrição', value: 'description'},
          { text: 'Ações', sortable: false },
        ]
      }
    },
  }
</script>